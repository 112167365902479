const BaseSessions = {
  correctFacebookRedirectUri: function() {
    window.location.href.indexOf('#_=_') > 0 && (window.location = window.location.href.replace(/#.*/, ''));
  },
  removeBlankFreeword: function() {
    const parameters = QueryString.parse(location.search);

    if (typeof parameters.freeword === 'undefined' || parameters.freeword.length) return;

    delete parameters.freeword;
    location.search = QueryString.stringify(parameters);
  },
  loads: function() {
    BaseSessions.correctFacebookRedirectUri();
    BaseSessions.removeBlankFreeword();
  }
};

$(document).on('turbolinks:load', function() {
  BaseSessions.loads();
});
